import Section from '../../layout/Section';
import { Col } from 'react-bootstrap';
import classes from './Documentation.module.css';
import Accordion from 'react-bootstrap/Accordion';
import Link from '../Link';

const Documentation = () => {
  const playgroundLink = <Link text="Playground" url="/playground" leavesPage={false} />;
  const proposal64 = (
    <Link text="proposal 64" url="https://lilnouns.wtf/vote/64" leavesPage={true} />
  );
  const paradigm = (
    <Link
      text="Paradigm's implementation"
      url="https://www.paradigm.xyz/2022/08/vrgda"
      leavesPage={true}
    />
  );
  const publicDomainLink = (
    <Link
      text="public domain"
      url="https://creativecommons.org/publicdomain/zero/1.0/"
      leavesPage={true}
    />
  );
  const compoundGovLink = (
    <Link text="Compound Governance" url="https://compound.finance/governance" leavesPage={true} />
  );

  const lilBlockPartyLink = (
    <Link text="Lil Block Party" url="https://lilblockparty.wtf" leavesPage={true} />
  );

  return (
    <Section fullWidth={false}>
      <Col lg={{ span: 10, offset: 1 }}>
        <div className={classes.headerWrapper}>
          <h1>WTF?</h1>
          <p className={classes.aboutText}>
            LilSandbox.wtf is a testnet for lil nouns DAO to test out a new minting mechanism for
            lil nouns using a Variable Rate Gradual Dutch Auction mechanism instead of 15-minute
            english auctions.
            <br />
            <br />
            Lilsandbox NFTs are minted on the goerli testnet. They have no voting rights in lil
            nouns DAO.
          </p>
          <p className={classes.aboutText} style={{ paddingBottom: '4rem' }}>
            Lilsandbox was produced by {proposal64}.
          </p>
        </div>
        <Accordion flush>
          <Accordion.Item eventKey="0" className={classes.accordionItem}>
            <Accordion.Header className={classes.accordionHeader}>Summary</Accordion.Header>
            <Accordion.Body>
              <ul>
                <li>Lil Nouns artwork is in the {publicDomainLink}.</li>
                <li>
                  Lil Sandbox Nouns are born and trustlessly auctioned via a Gradual Dutch Auction,
                  forever.
                </li>
                <li>Settlement of one auction kicks off the next.</li>
                <li>Lil Sandbox Nouns are NOT members of Lil Nouns DAO.</li>
                <li>Artwork is generative and stored directly on-chain (not IPFS).</li>
                <li>
                  No explicit rules exist for attribute scarcity; all Lil Sandbox Nouns are equally
                  rare.
                </li>
              </ul>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="1" className={classes.accordionItem}>
            <Accordion.Header className={classes.accordionHeader}>
              Variable Rate Gradual Dutch Auctions
            </Accordion.Header>
            <Accordion.Body>
              <p className={classes.aboutText}>
                The lil sandbox nouns Variable Rate Gradual Dutch Auction, forked from {paradigm}
              </p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="3" className={classes.accordionItem}>
            <Accordion.Header className={classes.accordionHeader}>
              Lil Sandbox Nouns is not a DAO
            </Accordion.Header>
            <Accordion.Body>
              Lil Sandbox is just a goerli testnet. Sandbox lils have no voting rights.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="4" className={classes.accordionItem}>
            <Accordion.Header className={classes.accordionHeader}>
              Lil Sandbox Noun Traits
            </Accordion.Header>
            <Accordion.Body>
              <p>
                Lil Sandbox Nouns are generated randomly based on Goerli block hashes. There are no
                'if' statements or other rules governing Lil Noun trait scarcity, which makes all
                Lil Nouns equally rare. As of this writing, Lil Nouns are made up of:
              </p>
              <ul>
                <li>backgrounds (2) </li>
                <li>bodies (30)</li>
                <li>accessories (140) </li>
                <li>heads (242) </li>
                <li>glasses (23)</li>
              </ul>
              You can experiment with off-chain Lil Sandbox Noun generation at the {playgroundLink}.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="5" className={classes.accordionItem}>
            <Accordion.Header className={classes.accordionHeader}>
              On-Chain Artwork
            </Accordion.Header>
            <Accordion.Body>
              <p>
                Lil Nouns are stored directly on Goerli and do not utilize pointers to other
                networks such as IPFS. This is possible because Lil Sandbox Noun parts are
                compressed and stored on-chain using a custom run-length encoding (RLE), which is a
                form of lossless compression.
              </p>

              <p>
                The compressed parts are efficiently converted into a single base64 encoded SVG
                image on-chain. To accomplish this, each part is decoded into an intermediate format
                before being converted into a series of SVG rects using batched, on-chain string
                concatenation. Once the entire SVG has been generated, it is base64 encoded.
              </p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="6" className={classes.accordionItem}>
            <Accordion.Header className={classes.accordionHeader}>
              Lil Sandbox Noun Seeder Contract
            </Accordion.Header>
            <Accordion.Body>
              <p>
                The Lil Sandbox Noun Seeder contract is used to determine Lil Noun traits during the
                minting process. The seeder contract can be replaced to allow for future trait
                generation algorithm upgrades. Currently, Lil Sandbox Noun traits are determined
                using pseudo-random number generation:
              </p>
              <code>keccak256(abi.encodePacked(blockhash(block.number - 1), nounId))</code>
              <br />
              <br />
              <p>
                Trait generation is not truly random. Traits can be predicted when minting a Lil
                Noun on the pending block.
              </p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="7" className={classes.accordionItem}>
            <Accordion.Header className={classes.accordionHeader}>
              Lil Sandbox Nounder's
            </Accordion.Header>
            <Accordion.Body>
              <p>
                'Lil Sandbox Nounders' are the group of builders that initiated Lil Nouns. Here are
                the Lil Nounders:
              </p>
              <ul>
                <li>
                  <Link text="@nvonpentz" url="https://twitter.com/nvonpentz" leavesPage={true} />
                </li>
                <li>
                  <Link text="@rocketman" url="https://twitter.com/rocketman_w" leavesPage={true} />
                </li>
                <li>
                  <Link text="@adelusiam" url="https://twitter.com/adelidusiam" leavesPage={true} />
                </li>
                <li>
                  <Link text="@lilpanda" url="https://twitter.com/betterlawMLA" leavesPage={true} />
                </li>
              </ul>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Col>
    </Section>
  );
};
export default Documentation;
