import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { Auction } from '../../wrappers/nounsAuction';
import { useState } from 'react';
import PriceDropModal from '../PriceDropModal';
import BigNumber from 'bignumber.js';

dayjs.extend(duration);

const PriceBlock: React.FC<{
  bgColor: string;
  isActive: boolean;
}> = props => {
  const { bgColor, isActive } = props;
  return (
    <>
      <div
        style={{
          border: isActive ? '3px solid #000' : 'none',
        }}
        className={`md:w-[30px] md:h-[30px] w-[21px] h-[21px] bg-[var(--brand-warm-accent)] ${bgColor}`}
      ></div>
    </>
  );
};

const PriceRange: React.FC<{
  auction: Auction;
}> = props => {
  const { auction } = props;

  if (!auction) return null;

  const [showPriceDropModal, setShowPriceDropModal] = useState(false);
  const showPriceDropHandler = () => {
    setShowPriceDropModal(true);
  };
  const dismissPriceDropModal = () => {
    setShowPriceDropModal(false);
  };

  const MAX_PRICE = 1;
  const MIN_PRICE = new BigNumber(auction.reservePrice.toHexString()).toNumber() / 10 ** 18 || 0.01;

  const MID_PRICE = MAX_PRICE - (MAX_PRICE - MIN_PRICE) / 2;

  const currentPrice = new BigNumber(auction.amount.toHexString()).toNumber() / 10 ** 18;

  const numPriceBlocks = 15;

  const activeIndex = Math.floor((currentPrice / MAX_PRICE) * numPriceBlocks);

  const colorsClassNames = [
    'bg-[#2B83F6]',
    'bg-[#2B83F6]',
    'bg-[#2B83F6]',
    'bg-[#5D9DC3]',
    'bg-[#8FB78E]',
    'bg-[#C6D254]',
    'bg-[#FFEF17]',
    'bg-[#FFEF17]',
    'bg-[#FFEF17]',
    'bg-[#FFCB37]',
    'bg-[#FFB946]',
    'bg-[#FF9564]',
    'bg-[#FF638D]',
    'bg-[#FF638D]',
    'bg-[#FF638D]',
  ];

  return (
    <>
      {showPriceDropModal && <PriceDropModal onDismiss={dismissPriceDropModal} />}
      <div className="inline-block relative">
        <div className="flex lg:justify-between justify-start lg:space-x-0 md:space-x-48 space-x-32">
          <h4
            className="mb-0 font-bold"
            style={{
              color: 'var(--brand-warm-light-text)',
            }}
          >
            Ξ{MIN_PRICE}
          </h4>
          <h4
            className="mb-0 font-bold"
            style={{
              color: 'var(--brand-warm-light-text)',
            }}
          >
            Ξ{MID_PRICE}
          </h4>

          <h4
            className="mb-0 font-bold"
            style={{
              color: 'var(--brand-warm-light-text)',
            }}
          >
            Ξ{MAX_PRICE}
          </h4>
        </div>

        <div className="flex flex-row items-center justify-start space-x-[4px] mt-[8px]">
          {colorsClassNames.map((color, index) => {
            return <PriceBlock bgColor={color} isActive={index === activeIndex} />;
          })}
        </div>
      </div>
    </>
  );
};

export default PriceRange;
