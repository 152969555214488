import classes from './WalletConnectWagmi.module.css';
import ReactDOM from 'react-dom';
import React, { useEffect } from 'react';
import { XIcon } from '@heroicons/react/solid';
import { useAccount, useConnect } from 'wagmi';

export const Backdrop: React.FC<{ onDismiss: () => void }> = props => {
  return <div className={classes.backdrop} onClick={props.onDismiss} />;
};

const WalletConnectWagmiModalOverlay: React.FC<{
  onDismiss: () => void;
}> = props => {
  const { onDismiss } = props;

  const { connect, connectors, error, isLoading, pendingConnector } = useConnect();
  return (
    <>
      <div className={classes.closeBtnWrapper}>
        <button onClick={onDismiss} className={classes.closeBtn}>
          <XIcon className={classes.icon} />
        </button>
      </div>

      <div className={classes.modal}>
        <div className={classes.content}>
          <div className={classes.header}>
            <div className={classes.title}>
              <h2>Wallet</h2>
              <h1>Connect Wallet</h1>
              <div>
                {connectors.map(connector => (
                  <button
                    disabled={!connector.ready}
                    key={connector.id}
                    onClick={() => connect({ connector })}
                  >
                    {connector.name}
                    {!connector.ready && ' (unsupported)'}
                    {isLoading && connector.id === pendingConnector?.id && ' (connecting)'}
                  </button>
                ))}

                {error && <div>{error.message}</div>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const WalletConnectWagmiModal: React.FC<{
  onDismiss: () => void;
}> = props => {
  const { onDismiss } = props;

  const { address } = useAccount();

  useEffect(() => {
    if (address) {
      onDismiss();
    }
  }, [address]);
  return (
    <>
      {ReactDOM.createPortal(
        <Backdrop onDismiss={onDismiss} />,
        document.getElementById('backdrop-root')!,
      )}
      {ReactDOM.createPortal(
        <WalletConnectWagmiModalOverlay onDismiss={onDismiss} />,
        document.getElementById('overlay-root')!,
      )}
    </>
  );
};

export default WalletConnectWagmiModal;
