import { useAppSelector } from '../../hooks';
import React, { useState, useRef } from 'react';

import classes from './Buy.module.css';
import { Spinner, InputGroup, Button } from 'react-bootstrap';
import { Auction } from '../../wrappers/nounsAuction';
import { useAppDispatch } from '../../hooks';

import { NounsAuctionHouseFactory } from '@lilnounsdao/sdk';
import config from '../../config';
import WalletConnectModal from '../WalletConnectModal';
import InfoModal from '../InfoModal';
import AUCTION_ABI from '../../libs/abi/vrgda.json';
import { useAccount, useConnect, useContractWrite, usePrepareContractWrite } from 'wagmi';
import WalletConnectWagmiModal from '../WalletConnectWagmiModal';

export const GOERLI_VRGDA_CONTRACT = '0xaf71644feeaf6439015d57631f59f8e0e0f91c67';

const Buy: React.FC<{
  auction: Auction;
  auctionEnded: boolean;
}> = props => {
  const activeAccount = useAppSelector(state => state.account.activeAccount);

  const { auction, auctionEnded } = props;

  const { address } = useAccount();

  const { connectAsync } = useConnect();

  const [showWagmiConnectModal, setShowWagmiConnectModal] = useState(false);

  const dismissConnectModal = () => {
    setShowWagmiConnectModal(false);
  };

  const nounsAuctionHouseContract = new NounsAuctionHouseFactory().attach(
    config.addresses.nounsAuctionHouseProxy,
  );

  const account = useAppSelector(state => state.account.activeAccount);

  const bidInputRef = useRef<HTMLInputElement>(null);

  const [bidInput, setBidInput] = useState('');
  const [bidButtonContent, setBidButtonContent] = useState({
    loading: false,
    content: auctionEnded ? 'Settle' : 'Buy Now',
  });

  const [showConnectModal, setShowConnectModal] = useState(false);

  const hideModalHandler = () => {
    setShowConnectModal(false);
  };

  const args = [auction.nounId, auction.parentBlockHash];

  const { write } = useContractWrite({
    address: GOERLI_VRGDA_CONTRACT,
    abi: AUCTION_ABI,
    functionName: 'settleAuction',
    args,
    chainId: 5,
    mode: 'recklesslyUnprepared',
    overrides: {
      gasLimit: 5000000 as any,
      maxFeePerGas: 2000000000 as any,
      from: address,
      value: auction.amount,
    },
  });

  const dispatch = useAppDispatch();

  const buyNounHandler = async () => {
    if (!address) {
      setShowWagmiConnectModal(true);
      await connectAsync();
    }
    if (write) {
      write?.();
    }
  };
  const clearBidInput = () => {
    if (bidInputRef.current) {
      bidInputRef.current.value = '';
    }
  };

  //TODO: Refactor Modal to utilitse new modal design
  const [showBidHistoryModal, setShowBidHistoryModal] = useState(false);
  const showBidModalHandler = () => {
    setShowBidHistoryModal(true);
  };
  const dismissBidModalHanlder = () => {
    setShowBidHistoryModal(false);
  };

  if (!auction) return null;

  const isDisabled = false;
  const isWalletConnected = activeAccount !== undefined;

  return (
    <>
      {showBidHistoryModal && <InfoModal onDismiss={dismissBidModalHanlder} />}

      {showConnectModal && activeAccount === undefined && (
        <WalletConnectModal onDismiss={hideModalHandler} />
      )}
      {showWagmiConnectModal && <WalletConnectWagmiModal onDismiss={dismissConnectModal} />}

      <InputGroup>
        {!auctionEnded && (
          <>
            <Button
              className={auctionEnded ? classes.bidBtnAuctionEnded : classes.bidBtn}
              onClick={buyNounHandler}
              disabled={!write}
            >
              {bidButtonContent.loading ? <Spinner animation="border" /> : bidButtonContent.content}
            </Button>
          </>
        )}
      </InputGroup>
    </>
  );
};
export default Buy;
